.container, .cares-me-not {
    transition: transform 1s cubic-bezier(0, .63, .33, 1), opacity 1s cubic-bezier(0, .63, .33, 1);

    &.content-background {
        transform: translateZ(-2em);
        opacity: 0.3;
    }
}

.smooth-resize.width{
    transition: width cubic-bezier(0, .63, .33, 1) 0.3s;
}

.smooth-resize.height{
    transition: height cubic-bezier(0, .63, .33, 1) 0.3s;
}

.cares-me-not.content-background{
    transform: translateZ(-3em);
}

.anim-links {
    visibility: hidden;
    animation: fade-in 1s cubic-bezier(.03, -0.03, 0, 1.01) 1.1s forwards;
    -webkit-animation: fade-in 1s cubic-bezier(.03, -0.03, 0, 1.01) 1.1s forwards;
}

.anim-logo-left {
    opacity: 0;
    transform: translateX(-60%);
    animation: logo-left 1s cubic-bezier(.03, -0.03, 0, 1.01) .91s forwards, fade-in 1s cubic-bezier(0, .39, .64, 1) .25s forwards;
    -webkit-animation: logo-left 1s cubic-bezier(.03, -0.03, 0, 1.01) .91s forwards, fade-in 1s cubic-bezier(0, .39, .64, 1) .25s forwards;
    -webkit-transform: translateX(-60%);
    -moz-transform: translateX(-60%);
    -ms-transform: translateX(-60%);
    -o-transform: translateX(-60%);
}

.anim-logo-right {
    opacity: 0;
    transform: translateX(60%);
    animation: logo-right 1s cubic-bezier(.03, -0.03, 0, 1.01) .93s forwards, fade-in 1s cubic-bezier(0, .39, .64, 1) .65s forwards;
    -webkit-animation: logo-right 1s cubic-bezier(.03, -0.03, 0, 1.01) .93s forwards, fade-in 1s cubic-bezier(0, .39, .64, 1) .65s forwards;
    -webkit-transform: translateX(60%);
    -moz-transform: translateX(60%);
    -ms-transform: translateX(60%);
    -o-transform: translateX(60%);
}

.anim-logo-top {
    opacity: 0;
    transform: translateY(60%);
    animation: logo-top 1s cubic-bezier(.03, -0.03, 0, 1.01) .92s forwards, fade-in 1s cubic-bezier(0, .39, .64, 1) .4s forwards;
    -webkit-animation: logo-top 1s cubic-bezier(.03, -0.03, 0, 1.01) .92s forwards, fade-in 1s cubic-bezier(0, .39, .64, 1) .4s forwards;
    -webkit-transform: translateY(60%);
    -moz-transform: translateY(60%);
    -ms-transform: translateY(60%);
    -o-transform: translateY(60%);
}

.anim-logo-bottom {
    opacity: 0;
    transform: translateY(-60%);
    animation: logo-bottom 1s cubic-bezier(.03, -0.03, 0, 1.01) .94s forwards, fade-in 1s cubic-bezier(0, .39, .64, 1) .88s forwards;
    -webkit-animation: logo-bottom 1s cubic-bezier(.03, -0.03, 0, 1.01) .94s forwards, fade-in 1s cubic-bezier(0, .39, .64, 1) .88s forwards;
    -webkit-transform: translateY(-60%);
    -moz-transform: translateY(-60%);
    -ms-transform: translateY(-60%);
    -o-transform: translateY(-60%);
}

.navbar-container {
    transition: transform 0.5s cubic-bezier(.49,.01,1,.7);
    transform: translateY(0%);

    &.mobile{
        &.invisible, &.hidden{
            transform: translateY(-100%);
        }
    }

    &.hidden{
        transform: translateY(-100%);
    }
}

.sidebar {
    transition: transform 0.5s cubic-bezier(0, .63, .33, 1), display 0.5s cubic-bezier(0, .63, .33, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0, .63, .33, 1), display 0.5s cubic-bezier(0, .63, .33, 1);
    -moz-transition: transform 0.5s cubic-bezier(0, .63, .33, 1), display 0.5s cubic-bezier(0, .63, .33, 1);
    -ms-transition: transform 0.5s cubic-bezier(0, .63, .33, 1), display 0.5s cubic-bezier(0, .63, .33, 1);
    -o-transition: transform 0.5s cubic-bezier(0, .63, .33, 1), display 0.5s cubic-bezier(0, .63, .33, 1);
}

.sidebar-hide {
    transform: translateX(-101%);
}

.sidebar-show {
    animation: sidebar-in 1s cubic-bezier(0, .63, .33, 1) backwards;
    -webkit-animation: sidebar-in 1s cubic-bezier(0, .63, .33, 1) backwards;
}

.error-shake {
    animation: quick-shake steps(12) .4s forwards;
    -webkit-animation: quick-shake steps(12) .4s forwards;
}

.loading-icon {
    margin: auto;
    width: 8em;
    height: 8em;
    background-image: url("../media/loading-strip.png");
    background-size: cover;
    background-position: left;
    animation: loading-icon .9s steps(24) infinite;
    -webkit-animation: loading-icon .9s steps(24) infinite;
}

@keyframes quick-shake {
    0% {
        transform: translateY(50%);
        -webkit-transform: translateY(50%);
        -moz-transform: translateY(50%);
        -ms-transform: translateY(50%);
        -o-transform: translateY(50%);
    }

    10% {
        transform: translateY(-40%);
        -webkit-transform: translateY(-40%);
        -moz-transform: translateY(-40%);
        -ms-transform: translateY(-40%);
        -o-transform: translateY(-40%);
    }

    20% {
        transform: translateY(30%);
        -webkit-transform: translateY(30%);
        -moz-transform: translateY(30%);
        -ms-transform: translateY(30%);
        -o-transform: translateY(30%);
    }

    30% {
        transform: translateY(-20%);
        -webkit-transform: translateY(-20%);
        -moz-transform: translateY(-20%);
        -ms-transform: translateY(-20%);
        -o-transform: translateY(-20%);
    }

    40% {
        transform: translateY(10%);
        -webkit-transform: translateY(10%);
        -moz-transform: translateY(10%);
        -ms-transform: translateY(10%);
        -o-transform: translateY(10%);
    }

    50% {
        transform: translateY(-5%);
        -webkit-transform: translateY(-5%);
        -moz-transform: translateY(-5%);
        -ms-transform: translateY(-5%);
        -o-transform: translateY(-5%);
    }

    60% {
        transform: translateY(5%);
        -webkit-transform: translateY(5%);
        -moz-transform: translateY(5%);
        -ms-transform: translateY(5%);
        -o-transform: translateY(5%);
    }

    60% {
        transform: translateY(-3%);
        -webkit-transform: translateY(-3%);
        -moz-transform: translateY(-3%);
        -ms-transform: translateY(-3%);
        -o-transform: translateY(-3%);
    }

    70% {
        transform: translateY(3%);
        -webkit-transform: translateY(3%);
        -moz-transform: translateY(3%);
        -ms-transform: translateY(3%);
        -o-transform: translateY(3%);
    }

    80% {
        transform: translateY(-1%);
        -webkit-transform: translateY(-1%);
        -moz-transform: translateY(-1%);
        -ms-transform: translateY(-1%);
        -o-transform: translateY(-1%);
    }

    100% {
        transform: translateY(0%);
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
    }
}

@keyframes fade-in {
    0% {
        visibility: visible;
        opacity: 0;
    }

    100% {
        visibility: visible;
        opacity: 1;
    }
}

@keyframes logo-left {
    0% {
        transform: translateX(-60%);
        -webkit-transform: translateX(-60%);
        -moz-transform: translateX(-60%);
        -ms-transform: translateX(-60%);
        -o-transform: translateX(-60%);
    }

    100% {
        transform: translateX(0%);
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
    }
}

@keyframes logo-top {
    0% {
        transform: translateY(60%);
        -webkit-transform: translateY(60%);
        -moz-transform: translateY(60%);
        -ms-transform: translateY(60%);
        -o-transform: translateY(60%);
    }

    100% {
        transform: translateY(0%);
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
    }
}

@keyframes logo-bottom {
    0% {
        transform: translateY(-60%);
        -webkit-transform: translateY(-60%);
        -moz-transform: translateY(-60%);
        -ms-transform: translateY(-60%);
        -o-transform: translateY(-60%);
    }

    100% {
        transform: translateY(0%);
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
    }
}

@keyframes logo-right {
    0% {
        transform: translateX(60%);
        -webkit-transform: translateX(60%);
        -moz-transform: translateX(60%);
        -ms-transform: translateX(60%);
        -o-transform: translateX(60%);
    }

    100% {
        transform: translateX(0%);
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
    }
}

@keyframes loading-icon {
    0% {
        background-position: left;
    }

    100% {
        background-position: right;
    }
}

@keyframes sidebar-out {
    0% {
        transform: translateX(0%);
    }

    100% {}
}

@keyframes sidebar-in {
    0% {
        transform: translateX(-101%);
    }

    100% {
        transform: translateX(0%);
    }
}