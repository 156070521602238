@import "~foundation-sites/scss/foundation";
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&Roboto+Mono:wght@300;400;700&display=swap');
@include foundation-xy-grid-classes(
    /* options
  $base-grid: true,
  $margin-grid: true,
  $padding-grid: true,
  $block-grid: true,
  $collapse: true,
  $offset: true,
  $vertical-grid: true,
  $frame-grid: true
  */
);
@include foundation-flex-classes;

$topbar-height: 1.5em;
$light-input-bg: rgba(255, 255, 255, 0.3);
$fonts: 'Roboto',
'Arial',
'sans-serif';
$input-fonts: 'Roboto Condensed',
'Roboto',
'Arial',
'sans-serif';
$fonts-mono: 'Inconsolata',
'Courier New',
'monospace';

body,
html {
    perspective: 3cm;
    width: 100vw;
    height: 100vh;
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-family: $fonts;
    background-color: #070707;
}

body {
    &::after {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        z-index: -1; // hide images
        content: url("../media/cmts_results.png") url("../media/cmts_status_strip.png") url("../media/cmts_type_strip.png") url("../media/cmts-load-strip.png"); // load images
    }
}

#root {
    width: 100vw;
    height: 100vh;
    min-height: 100%;
    margin: 0;
    padding: 0;
}

.container {
    overflow: visible;
    width: 100%;
    height: 100%;
}

.input-eater {
    position: fixed;
    height: 100%;
    width: 100%;
    inset: 0;
    z-index: 3;
    background-color: transparent;
}

.page-bounds {
    width: 100%;
    top: 4em;
    bottom: 0;
    position: fixed;
}

.hidden {
    opacity: 0;
    visibility: none;
    z-index: -1;
}

span.icon {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 1.5em;
    width: 1.5em;
    margin: 0.6em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconArray {
    display: inline-flex;
    font-size: 2em;

    a {
        color: #EEE;
        text-decoration: none;
    }

    * {
        padding-right: 0.5em;
    }
}

.foreign-icon {
    width: 1em;
    height: 1em;
    background-size: contain;
    background-repeat: no-repeat;
}

span.icon {
    &.comet-logo {
        background-image: url("../media/Comet.png");
    }

    &.glitchypsi-logo {
        background-image: url("../media/GlitchyPSI.png");
    }

    &.spectrum-logo {
        background-image: url("../media/Spectrum.png");
    }
}

.content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    flex-direction: column;
    padding: 0em 5em 5em 5em;
    transition: transform 0.5s cubic-bezier(0, .63, .33, 1), opacity 0.5s cubic-bezier(0, .63, .33, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0, .63, .33, 1), opacity 0.5s cubic-bezier(0, .63, .33, 1);
    -moz-transition: transform 0.5s cubic-bezier(0, .63, .33, 1), opacity 0.5s cubic-bezier(0, .63, .33, 1);
    -ms-transition: transform 0.5s cubic-bezier(0, .63, .33, 1), opacity 0.5s cubic-bezier(0, .63, .33, 1);
    -o-transition: transform 0.5s cubic-bezier(0, .63, .33, 1), opacity 0.5s cubic-bezier(0, .63, .33, 1);

    div {
        margin: 0;
        padding: 0;
    }
}

h1,
h2,
h3 {
    color: #EEE;
}

.centered-flex {
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

button.blank-button {
    color: white;
    border: none;
    background: transparent;
    padding: .5em;
    font-size: 1.5em;
    align-items: center;
}

.sidebar {
    z-index: 3;
    background-color: #366FFF;
    display: inline-block;
    position: absolute;
    height: 100vh;
    min-width: 20%;

    ul {
        margin: 0;
        padding: 0;
        font-size: 1.5em;
    }

    a {
        color: #EEE;
        text-decoration: none;
        font-weight: 100;
    }

    span {
        color: #EEE;
        text-decoration: none;
        font-weight: 100;
    }

    li {
        list-style-type: none;
        margin: 0;
        display: flex;
        padding: 0.2em 0.5em;
        border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
        background-color: rgba(0, 0, 0, 0.1);
        align-items: center;
    }

    li.active {
        border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
        background-color: rgba(0, 0, 0, 0.8);
    }
}

input[type=range] {
    -webkit-appearance: none;
    /* Hides the slider so that custom slider can be made */
    width: 100%;
    /* Specific width is required for Firefox. */
    background: transparent;
    /* Otherwise white in Chrome */

    padding: 1em 0em;

    @mixin thumb-style($focus) {
        height: 1.5em;
        width: 1.5em;
        border-radius: 0.25em;
        @if ($focus == 1){
            background: rgb(190, 190, 190);
            border: 0.125em solid $light-input-bg;
        }
        @else{
            background: rgb(180, 180, 180);
        }
        
        cursor: pointer;
    }

    @mixin track-style {
        width: 100%;
        height: 0.30em;
        cursor: pointer;
        background: $light-input-bg;
        border-radius: 1.3px;
    }

    &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        @include track-style();
    }

    &:focus::-webkit-slider-runnable-track {
        background: rgba(128, 128, 128, 0.9);
    }

    &::-moz-range-track {
        @include track-style();
    }

    &::-ms-track {
        @include track-style();
    }

    &::-webkit-slider-thumb {
        @include thumb-style(0);
        -webkit-appearance: none;
        margin-top: calc(-1em / 2);
        /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    }

    &:focus::-webkit-slider-thumb {
        @include thumb-style(1);
        -webkit-appearance: none;
        margin-top: calc(-1em / 2);
        /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    }

    &::-moz-range-thumb {
        @include thumb-style(0);
    }

    &::-ms-thumb {
        @include thumb-style(0);
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
    }

    &:focus {
        outline: 0.25em white;
    }

    &::-ms-track {
        width: 100%;
        cursor: pointer;

        /* Hides the slider so custom styles can be added */
        background: transparent;
        border-color: transparent;
        color: transparent;
    }



}


.searchBar {
    backdrop-filter: blur(4px);
    background-color: $light-input-bg;
    border-radius: .5em;
    -webkit-border-radius: .5em;
    -moz-border-radius: .5em;
    -ms-border-radius: .5em;
    padding: .1em;
    -o-border-radius: .5em;
    display: inline-flex;

    input {
        font-size: larger;
        background: none;
        outline: none;
        border: none;
        flex: 1;
        padding: .5rem;
    }

    &.medium-text input {
        font-size: medium;
    }

    & .searchButton {
        background: none;
        border: none;
        margin: .3em;
        padding: 0;
        align-items: center;

        & .image {
            max-width: 1.5rem;
            max-height: 1.5rem;

        }
    }
}

.button,
button {

    &.decorativeButton {
        transition: background-position 0.1s ease-out;
        -webkit-transition: background-position 0.1s ease-out;
        -moz-transition: background-position 0.1s ease-out;
        -ms-transition: background-position 0.1s ease-out;
        -o-transition: background-position 0.1s ease-out;
        box-sizing: border-box;
        background: none;
        border: none;
        font-size: 1em;
        padding: 0;
        margin: 0;
        background-color: transparent;
        background-image: radial-gradient(ellipse at 0 0, rgba(151, 151, 151, 0.6) 0%, rgba(255, 255, 255, 0) 70%);
        background-position: -4em -4em;
        background-repeat: no-repeat;
        cursor: pointer;
        outline: none;

        &:hover {
            background-position: -1em -1em;
            background-color: transparent;
        }

        &:active,
        &:focus {
            background-position: 0em 0em;
        }

        .icon,
        span {
            padding: initial;
        }
    }

    transform: scale(1);
    margin: 0em .5em;
    font-family: $input-fonts;
    text-transform: uppercase;
    font-size: 1rem;
    display: inline-flex;
    justify-items: stretch;
    padding: .7em;
    border: none;
    border-radius: .3em;
    -webkit-border-radius: .3em;
    -moz-border-radius: .3em;
    -ms-border-radius: .3em;
    -o-border-radius: .3em;
    background-color: rgba(12, 12, 12, 0.7);
    color: #FFF;
    transition: background-color 100ms ease-out,
    transform 70ms ease-in-out;
    -webkit-transition: background-color 100ms ease-out,
    transform 70ms ease-in-out;
    -moz-transition: background-color 100ms ease-out,
    transform 70ms ease-in-out;
    -ms-transition: background-color 100ms ease-out,
    transform 70ms ease-in-out;
    -o-transition: background-color 100ms ease-out,
    transform 70ms ease-in-out;

    .readable-content &,
    .modal-content & {
        background-color: rgba(200, 200, 200, 0.3);
    }

    &.empty-button,
    &.empty-button:hover,
    &.empty-button:active{
        background-color: transparent;
    }

    &:hover {
        background-color: rgba(30, 30, 30, 0.7);
    }

    &.success {
        background-color: rgba(2, 30, 2, 0.7);
    }

    &.failure {
        background-color: rgba(30, 2, 0, 0.7);
    }

    &:active {
        transform: scale(0.9);
    }

    .icon,
    span {
        padding: 0em .5em;
    }

}

.readable-content {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 1em;
    padding: .5em;
    color: white;

    .input-area {
        display: flex;
        align-items: center;
        justify-content: center;

        textarea {
            border-radius: .25em;
            background-color: $light-input-bg;
            color: white;
            font-size: 1.3em;
            height: 100%;
            width: 100%;
            resize: none;
        }
    }


    a {
        color: white;
        font-weight: bold;
        text-decoration: none;

        &::after {
            font-size: 1em;
            align-self: flex-start;
            content: "↗";
        }

        &:active,
        &:visited {
            color: rgb(132, 184, 230);
        }

        &.reference {
            font-weight: initial;
            color: white;
            text-decoration: underline white dotted;

            &::after {
                font-size: .5em;
                content: "";
            }
        }
    }

    code {
        font-family: $fonts-mono;
        padding: 0.05em 0.25em;
        background-color: rgba(50, 50, 50, 0.8);
        border-radius: 0.25em;
    }

    ul {
        &>li {
            padding-bottom: 0.6em;
        }
    }

    .reader-container {
        padding: 1em;
    }
}

select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;

    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: center;

    font-family: $input-fonts;
    ;
    padding: 0.25em;
    padding-right: 1.25em;
    background-color: $light-input-bg;
    color: white;
    border-radius: 0.25em;
    border: none;
    font-size: 1.125em;

    &.fullwidth {
        width: 100%;
    }

    & optgroup {
        background-color: rgba(0, 0, 0, 0.5);

        &>option {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }

    & option {
        background-color: rgba(0, 0, 0, 0.5);
    }

    &.value-gradient {

        & option {
            color: white;
        }

        &> :nth-child(1) {
            background-color: hsl(0, 0, 0);
        }

        &> :nth-child(2) {
            background-color: hsl(0, 100, 7);
        }

        &> :nth-child(3) {
            background-color: hsl(0, 100, 14);
        }

        &> :nth-child(4) {
            background-color: hsl(0, 100, 28);
        }

        &> :nth-child(5) {
            background-color: hsl(0, 100, 43);
        }
    }
}

.button-array {

    button,
    a {

        @include breakpoint(medium down) {
            font-size: 0.9em;
        }

        &.active {
            background-color: rgba(128, 128, 128, 0.2);
        }

        &:hover {
            background-color: $light-input-bg;
            text-decoration: none;
        }

        &:active {
            transform: none;
            background-color: rgba(30, 30, 30, 0.3);
            text-decoration: none;
        }

        font-family: $input-fonts;
        text-decoration: none;
        padding: .5em;
        margin: 0;
        text-transform: none;
        border-radius: 0;
        font-size: 1.2em;
        border: none;
        color: white;
        margin-right:1px;
        backdrop-filter: blur(4px);
        background-color: rgba(255, 255, 255, 0.3);
        transition: none;
    }

    :first-child {
        margin-left: 1px;
        border-top-left-radius: .3em;
        border-bottom-left-radius: .3em;
    }

    :last-child {
        border-top-right-radius: .3em;
        border-bottom-right-radius: .3em;
        margin-right: 0;
    }

    &.dark {

        button,
        a {
            background-color: rgba(32, 32, 32, 0.5);

            &.active {
                background-color: rgba(16, 16, 16, 0.7);
            }

            &:hover {
                background-color: rgba(128, 128, 128, 0.4);
                text-decoration: none;
            }

            &:active {
                transform: none;
                background-color: rgba(0, 0, 0, 0.5);
                text-decoration: none;
            }
        }
    }
}

.topbar {
    ul {
        margin: 0;
        padding: 0;
        font-size: $topbar-height;
    }

    width: 100%;
    display: inline-flex;
    height: 4rem;

    li {
        list-style-type: none;
        margin: 0;
        display: inline-flex;
    }

    li.activeIcon {
        padding: 0 0.5em;
    }

    .decorativeButton {
        z-index: 4;
    }
}

.navbar-container {

    @include top-bar-container;
    @include top-bar-stacked;
    @include top-bar-unstack;
    display: inline-flex;
    width: 100%;
    align-items: center;

    ul,
    .top-bar-left,
    .top-bar-right {
        background-color: transparent;
        height: 100%;
    }

    &.mobile {
        margin: 0;
        padding: 0;
        z-index: 3;
        inset: 0;
        position: absolute;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-flow: column;
        background-color: rgba(0, 0, 0, 0.9);
        overflow-x: hidden;

        &.hidden {
            visibility: none;
        }

        ul,
        .top-bar-left,
        .top-bar-right {
            background-color: transparent;
            height: fit-content;
            margin: 0;
            padding: 0;
            width: 100%;
        }

        .back-button {
            width: 100%;
            display: flex;
            height: 2em;
            justify-content: flex-start;
            align-items: center;
            background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);

            button {
                flex: 1;
                font-size: 1em;
                color: white;
                border: none;
                background: transparent;
                margin: 1em;
            }
        }

        .top-bar-left {
            flex: 1;
        }

        ul {
            font-size: 1.5em;
            margin: 0;
            padding: 0;
            flex-direction: column;
            align-items: center;

            li {
                display: flex;
                list-style-type: none;
                height: 3.5em;
                border-bottom: 1px rgba(255, 255, 255, 0.6) solid;
            }
        }
    }
}

// styles
.navbar-container {
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.47) 9%);

    ul {
        background-color: transparent;
    }
}

.navbar {
    margin: 0;
    padding: 0;
    color: #EEE;
    font-weight: 100;

    a {
        text-decoration: none;
    }

    li {
        height: 100%;
        padding: 0 0.5em;
        align-items: center;
        color: #EEE;
        font-size: 0.6em;
        font-weight: 700;

        a {

            &:visited,
            &:active,
            & {
                color: inherit;
            }
        }
    }

    li:hover {
        background-color: rgba(255, 255, 255, 0.25);
    }

    li.active {
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.25) 0, rgba(0, 0, 0, 0) 100%);
        animation: hit-navbar-item 0.4s cubic-bezier(.49, .01, 1, .7) forwards;
    }

    @keyframes hit-navbar-item {
        0% {
            background-color: rgba(255, 255, 255, 0.5);
        }

        100% {
            background-color: rgba(255, 255, 255, 0);
        }
    }
}

.gpsigradient {
    background: #000000;
    background: -moz-linear-gradient(top, #000000 0%, #000000 59%, #070707 100%);
    background: -webkit-linear-gradient(top, #000000 0%, #000000 59%, #070707 100%);
    background: linear-gradient(to bottom, #000000 0%, #000000 59%, #070707 100%);
}

.cares-me-not {

    @mixin fade-mixin($bg_uri) {
        & {
            opacity: 1;
            background-image: linear-gradient(rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0.7)), $bg_uri;
            background-size: cover;
        }
    }

    &.not-ready {
        background-image: linear-gradient(rgba(0, 0, 0, 0.7),
                rgba(0, 0, 0, 0.7));
        background-size: 110%;
    }

    &.anniversary {
        @include fade-mixin(url("../media/anniversary_2.jpg"));
    }

    &.aurora {
        @include fade-mixin(url("../media/aurora.jpg"));
    }

    &.island {
        @include fade-mixin(url("../media/island.jpg"));
    }

    &.waterfall {
        @include fade-mixin(url("../media/waterfall.jpg"));
    }

    &.synth-l {
        @include fade-mixin(url("../media/synthL.jpg"));
    }

    &.synth-d {
        @include fade-mixin(url("../media/synthD.jpg"));
    }

    &.desert {
        @include fade-mixin(url("../media/desert.jpg"));
    }

    &.ttc {
        @include fade-mixin(url("../media/ttc.jpg"));
    }

    display: block;
    position: relative;
    transition: opacity 1s ease-out,
    background-image 0.5s linear,
    background-size 1s ease-out;
    content: "";
    opacity: 0;
    background-size: 120%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -999;
    background-position: center;
}


.nazgradient {
    width: 100%;
    height: 100%;
    background: #366fff;
    background: -moz-linear-gradient(top, #366fff 1%, #1d50b7 100%);
    background: -webkit-linear-gradient(top, #366fff 1%, #1d50b7 100%);
    background: linear-gradient(to bottom, #366fff 1%, #1d50b7 100%);
}