$panel-white: rgba(255, 255, 255, 0.3);
$panel-title: rgba(255, 255, 255, 0.1);

.share-textbox {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: .4em;
    padding: .1em;
    display: flex;

    input {
        text-align: center;
        font-size: large;
        background: none;
        outline: none;
        border: none;
        flex: 1;
        padding: .5rem;
    }
}

.accountTopBar {
    display: flex;
    align-items: center;

    & .pfp {
        margin-right: 1em;
        width: 2.5em;
        height: 2.5em;
        background-size: cover;
        background-position: center;
        border-radius: 0.3em;
        border: solid 2px white;
    }
}


.searchResults {

    img {
        max-width: 100%;
        height: auto;
        border-radius: .4em;
        -webkit-border-radius: .4em;
        -moz-border-radius: .4em;
        -ms-border-radius: .4em;
        -o-border-radius: .4em;
        -webkit-border-radius: .4em;
    }

    a {
        text-decoration: none;
    }

    .badgeTray {
        display: flex;
        align-items: center;
        justify-content: center;

        &>.icon {
            width: 3em;
            height: 3em;
            margin-right: -0.25em;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            &.mod {
                background-image: url("../media/badges/moderator.png");
            }

            &.patron {
                background-image: url("../media/badges/patron.png");
            }

            &.verified {
                background-image: url("../media/badges/verified.png");
            }
        }
    }

    .searchResult {
        backdrop-filter: blur(4px);
        align-content: center;
        margin: .5em 0;
        background: $panel-title;
        border-radius: 1em;
        -webkit-border-radius: 1em;
        -moz-border-radius: 1em;
        -ms-border-radius: 1em;
        -o-border-radius: 1em;
        -webkit-border-radius: 1em;

        .username {
            color: white;
            font-weight: 700;
        }

        .discriminator {
            color: #E0E0E0;
            font-weight: 100;
        }
    }
}

.bannerResults {
    &.bannerImage {
        background-image: url("../media/cmts_results.png");
    }

    height: 8em;
    padding: .3em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    align-content: center;
    justify-content: center;
    color: #FFF;
    font-weight: 700;
    font-family: -apple-system,
    "BlinkMacSystemFont",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
}

.statusIcon {
    &.loading {

        background-image: url("../media/cmts-load-strip.png");
        animation: loading-icon 2s steps(54) infinite;
        -webkit-animation: loading-icon 2s steps(54) infinite;

        &::after {
            // Image preloading
            position: absolute;
            width: 0;
            height: 0;
            overflow: hidden;
            z-index: -1;
            content: url("../media/cmts-load-strip.png");
        }
    }

    &.disconnect {
        background-position: 0 0%;
    }

    &.banned {
        background-position: -127px 0;
    }

    &.mant {
        background-position: -255px 0;
    }

    &.unplanned-mant {
        background-position: 510px 0;
    }

    &.login {
        background-position: -510px 0;
    }

    &.catastrophe {
        background-position: -637px 0;
    }

    &.notfound {
        background-position: 100% 0;
    }

    &::after {
        // Image preloading
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        z-index: -1;
        content: url("../media/cmts_status_strip.png");
    }

    background-blend-mode: color-dodge;
    margin: auto;
    width: 8em;
    height: 8em;
    background-size: cover;
    white-space: pre-wrap;
    background-position: left;
    background-image: url('../media/cmts_status_strip.png');
}

.status-text {
    color: white;
    font-weight: 700;
    text-align: center;
}

.empty-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}


// Modal animations
.share-modal {
    position: relative;
    transform: scaleY(0.9);
    opacity: 1;
    align-self: center;
    transition: opacity .5s cubic-bezier(0, .63, .33, 1), transform .5s cubic-bezier(0, .63, .33, 1);
    flex-grow: 1;

    .title {
        text-align: center;
    }

    .social-button {
        font-size: 1.6em;
        padding: .5em;
        border-radius: 6em;
        height: 2em;
        width: 2em;
        border: none;
        background-color: #555555;
        color: white;
        transition: background-color .5s ease-out;

        &.success {
            background-color: #1d7032
        }

        &.fb {
            background-color: #1778F2;
        }

        &.twt {
            background-color: #1DA1F2;
        }
    }

    .modal-content {
        margin: 0;
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(2px);
    }

    &:focus {
        outline: none;
    }

    &.ReactModal__Content--after-open {
        transform: scale(1);
        -webkit-transform: scaleY(1);
        -moz-transform: scaleY(1);
        -ms-transform: scaleY(1);
        -o-transform: scaleY(1);
    }

    &.ReactModal__Content--before-close {
        opacity: 0;
        transform: scaleY(0.8);
    }
}

.share-modal-overlay {
    inset: 0;
    position: absolute;
    align-content: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color .4s cubic-bezier(0, .63, .33, 1);

    &.ReactModal__Overlay--after-open {
        background-color: rgba(0, 0, 0, 0.8);
    }

    &.ReactModal__Overlay--before-close {
        background-color: rgba(0, 0, 0, 0);
    }

}

.panel {
    backdrop-filter: blur(4px);

    a {
        text-decoration: none;
        color: #FFF;
    }

    .icon {
        background-image: url("../media/cmts_type_strip.png");
        background-position: left;
        background-size: cover;
        border-radius: 4em;
        -webkit-border-radius: 4em;
        -moz-border-radius: 4em;
        -ms-border-radius: 4em;
        -o-border-radius: 4em;
        margin: 0em .25em;
        height: 1.5em;
        width: 1.5em;

        &.spark {
            background-position: 100% 0%;
        }

        &.classic {
            background-position: 0% 0%;
        }
    }

    .cc-name {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 0.25em;
        padding: 0.25em;

        & h3 {
            padding: 0 0 0 0.3em;
            margin: 0.25em;
        }

        & .icon {
            width: 2.5em;
            height: 2.5em;
            border-radius: 0.25em;
            padding: 0;
            margin: 0;
            background-color: rgba(56, 56, 56, 0.7);
        }
    }

    .list-element {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        margin: .1rem 0em;
        height: 2em;
        flex-flow: row;
        border-top-left-radius: 10em;
        border-bottom-left-radius: 10em;
        border-top-right-radius: 2em;
        border-bottom-right-radius: 2em;
        background-color: rgba(0, 0, 0, 0.7);

        &.active {
            background: linear-gradient(to left, rgba(56, 56, 56, 0.7), rgba(0, 0, 0, 0.7));
        }
    }

    .title {
        text-align: center;
        background-color: $panel-title;
        border-radius: .5em .5em 0 0;
        -webkit-border-radius: .5em .5em 0 0;
        -moz-border-radius: .5em .5em 0 0;
        -ms-border-radius: .5em .5em 0 0;
        padding: .1em;
        -o-border-radius: .5em .5em 0 0;
    }

    .bottom {
        background-color: $panel-white;
        border-radius: 0 0 .5em .5em;
        -webkit-border-radius: 0 0 .5em .5em;
        -moz-border-radius: 0 0 .5em .5em;
        -ms-border-radius: 0 0 .5em .5em;
        padding: .1em;
        -o-border-radius: 0 0 .5em .5em;
    }

    .standalone {
        background-color: $panel-white;
        border-radius: .5em;
    }

    .darker {
        margin: 1em;
        padding: 0.5em;
        width: auto;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 0.25em;
        color: white;
    }

    .button-array {
        button,
        a {
            backdrop-filter: none;
        }
    }

}

.cclist {
    max-height: 50vh;
    overflow: auto;
    scrollbar-color: rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0.7);
}